<template>
  <v-app id="app"  style="background: #6D4C41;">
    <!-- <v-app-bar
      flat
      outlined
      scroll-target="#scrolling-techniques-7"
      elevation="2"
      style="max-height: 64px;"
      color="brown darken-1"
    >
      <div class="d-flex align-center">

          <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="100"
            src="../public/assets/img/logo-hotely.png"
            width="150"
          />
        </div>

        <v-spacer></v-spacer>
        
    </v-app-bar> -->

    <v-btn
      color="brown darken-2"
      dark
      elevation="2"
      fab
      fixed
      top
      right
      @click="drawer = !drawer"
      style="top: 10px; right: 10px;"
      v-show="drawer == false"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="brown lighten-5"
    >
      <v-app-bar
        flat
        outlined
        elevation="0"
        style="max-height: 70px;"
        color="brown darken-1"
      >
        <div class="d-flex align-center">
            <v-img
              alt="Vuetify Name"
              class="shrink mt-1"
              contain
              min-width="100"
              src="../public/assets/img/logo-hotely.png"
              width="140"
            />
          </div>
          <v-spacer></v-spacer>
      </v-app-bar>
      <v-list
        nav
        dense
      >
        <!-- <v-subheader>
          
          <v-text-field
            label="Rechercher un catégorie"
            outlined
            v-model="searchCategorie"
            @input="fetchAll"
            olo dense hide-details
            ref="searchArticle"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>

        </v-subheader> -->
        <v-list-item-group
          active-class="brown--text text--accent-4"
        >
        
          <!-- <v-list-item :to="{ path: '/all' }">
            <v-list-item-icon class="mr-0 pr-0">
              <v-icon>mdi-asterisk</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-uppercase pl-1 mr-0">Toutes la catégorie</v-list-item-title>
          </v-list-item> -->

          <v-list-item v-for="(categorie, index) in categories" :key="index" :to="{ path: '/' + categorie.codecategorie }">
            <v-list-item-icon class="mr-0 pr-0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-uppercase pl-1 mr-0">{{ categorie.nom_categorie }}</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    

    
    <v-main class="pt-3">
      <v-img
          alt="Calypso"
          class="shrink mt-1"
          contain
          min-width="100"
          src="../../public/assets/img/logo_3.png"
          height="200"
      />
      <router-view :params="searchArticle"/>
      
    </v-main>

  </v-app>
</template>

<script>

// import $ from 'jquery';
import axios from "axios";
// import router from './router'
// import Vue from 'vue'

export default {
  name: 'App',

  components: {
  },

  data () {
    return {
      drawer: false,
      categories: [],
      searchCategorie: "",
      searchArticle: "",
      current_cat: ''
    }
  },
  methods: {
    fetchAll() {
        axios.get(this.BASE_URL + "/categories/fetchall?searchKey="+this.searchCategorie+"&statut=ACTIF").then(response => {
            this.categories = response.data;
        });
    },
    fetchAllArt() {
      // this.$route.params.searchArticle = this.searchArticle;
      // this.current_cat = this.$route.params.catId;
      
      this.$route.params.catId = this.current_cat + "*" + this.searchArticle;
      console.log(this.$route.params);
      console.log(this);
      
    },
  },
  mounted: function () {
    this.current_cat = this.$route.params.catId;
    this.fetchAll();
  },
  computed: {
    
  },
  created: function () {
    
  },
};
</script>
