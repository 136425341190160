
// import Vue from 'vue';
import axios from "axios";
import $ from 'jquery';
// Vue.use(VueAxios, axios)

export default {
    name: 'ArticlesList',
    components: {
    },
  
    data () {
      return {
        dialogConfirm: false,
        valid: null,
        statutBtnEnregistrer: true,
        dialogModal: false,
        modalTitle: "Ajouter une catégorie",
        searchKey: "",
        snackBar: {
            message: "",
            color: "grey darken-4",
            status: false
        },
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
            { 
                text: 'Photo', 
                value: 'photo',
                width: '60px',
                class: "text-uppercase",
                sortable: false,
            },
            {
              text: 'Catégorie',
              align: 'start',
              sortable: true,
              value: 'nom_categorie',
              class: "text-uppercase"
            },
            { 
                text: 'Description', 
                value: 'description',
                class: "text-uppercase" 
            },
            { 
                text: 'Statut', 
                value: 'statut',
                width: '100px',
                class: "text-uppercase",
            },
            { 
                text: '', 
                value: 'action',
                width: '140px',
            },
        ],
        articles: [],
        files: null,
        crudForm: {
            id: '',
            nom_categorie: '',
            description: '',
            photos: [],
            codecategorie: ''
        },
        rules: {
            textRequis: [
                v => !!v || 'Champs réquis',
            ],
            categorie: [val => (val || '').length > 0 || 'Catégorie réquis'],
        },
        deleteRowId: 0
      }
    },
    methods: {
        fetchAll() {
            axios.get(this.BASE_URL + "/categories/fetchall?searchKey="+this.searchKey).then(response => {
                this.articles = response.data;
            });
        },
        openModal: function() {
            this.crudForm = {
                id: '',
                nom_categorie: '',
                description: '',
                photos: [],
                codecategorie: ''
            };
            // this.$refs.form.reset();
            this.dialogModal = true;
            this.modalTitle = "Ajouter une catégorie";
            // this.$refs.form.reset();
            // this.$refs.form.resetValidation();
        },
        filter: function(evt) {
            evt = (evt) ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();
            
            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        editRow(row) {
            this.crudForm = row;
            var photos = [];
            for(var i = 0; i < row.photos.length; i++) {
                photos.push(
                    {
                        file_name: row.photos[i],
                        file_path: this.BASE_URL + '/assets/img/categories/',
                        statutphoto: 'old'
                    }
                );
            }
            this.crudForm.photos = photos;
            this.dialogModal = true;
            this.modalTitle = "Editer une catégorie";
        },
        
        chagerStatut(row) {

            this.crudForm = row;
            this.dialogConfirmStatut = true;
            

        },
        changerStatutAction() {

          let data = new FormData();
          data.append('id', this.crudForm.id);
          data.append('statut', this.crudForm.statut);

          let that = this;
          let config = {
              header: {
                  'Content-Type': 'multipart/form-data'
              },
          };
          axios.post(
            this.BASE_URL + "/categories/changerstatutarticle",
            data,
            config
          ).then(
              response => {
                
                that.dialogConfirmStatut = false;
                if (response.data.error === "") {
                  that.snackBar = {
                      message: response.data.message,
                      color: "green darken-2",
                      status: true
                  };
                    
                    that.fetchAll();
                } else {
                    
                    that.snackBar = {
                        message: response.data.error,
                        color: "red darken-2",
                        status: true
                    };
                }
              }
          ).catch(error => {
              console.log(error);
          })
        },
        deleteRow(row) {
            this.deleteRowId = row.id;
            this.dialogConfirm = true;
        },
        deleteAction() {
            let that = this;
            axios.get(this.BASE_URL + "/categories/delete/"+this.deleteRowId).then(response => {
                this.dialogConfirm = false;
                if (response.data.error === "") {
                    that.dialogModal = false;
                    that.snackBar = {
                        message: response.data.message,
                        color: "green darken-2",
                        status: true
                    };
                    
                    that.fetchAll();
                } else {
                    
                    that.snackBar = {
                        message: response.data.error,
                        color: "red darken-2",
                        status: true
                    };
                }

            });
        },
        submitForm () {

            let that = this;
            
            if(this.$refs.form.validate() == true) {
                this.statutBtnEnregistrer = false;
                var link = that.BASE_URL + "/categories/addaction";
                
                axios.post(link, $("#formulaire").serialize()).then(function (response) {
                    that.statutBtnEnregistrer = true;
                    if (response.data.error === "") {
                        that.dialogModal = false;
                        that.snackBar = {
                            message: response.data.message,
                            color: "green darken-2",
                            status: true
                        };
                        
                        that.fetchAll();
                    } else {
                        
                        that.snackBar = {
                            message: response.data.error,
                            color: "red darken-2",
                            status: true
                        };
                    }
                });
            }
            
        },
        SupprimerPhoto(index) {
            this.crudForm.photos.splice(index,1);
        },
        async uploadFile(event) {

            const URL = this.BASE_URL + '/categories/uploadaction';
            console.log(event);
            let files = event; //.target.files || event.dataTransfer.files;
            this.files = files;
            console.log(this.files.length);

            for (let i = 0; i < files.length; i++) {
                let data = new FormData();
                data.append('name', 'file-input');
                data.append('file-input', event[i]);

                let config = {
                    header: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
                axios.post(
                    URL,
                    data,
                    config
                ).then(
                    response => {
                        console.log('fichier upload response > ', response);
                        // this.preview_link_photo[i].nom_image = response.data.upload_data.file_name;
                        console.log(response.data);
                        this.crudForm.photos.push({
                            file_name: response.data.upload_data.file_name,
                            file_path: this.BASE_URL + '/fichiers/temp/',
                            statutphoto: "new"
                        });
                        // that.photos.photos= response.data.upload_data.file_name;
                    }
                ).catch(error => {
                    console.log(error);
                    this.snackBar = {
                        message: error,
                        color: "red darken-2",
                        status: true
                    };
                })
            }

        },

    },
    mounted: function () {
      this.fetchAll();
    }
  };