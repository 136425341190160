
import axios from "axios";
import VuePdfApp from "vue-pdf-app";

// import $ from 'jquery';

export default {
    name: 'ArticlesList',
    components: {
      VuePdfApp
    },
  
    data () {
      return {
        
        articlesCat: [],
        categories_id: "",
        panel: [],
        pdf_link: '',
        config: {
          toolbar: false,
        },
        // idConfig: {
          
        //   findNext: "vuePdfAppFindNext",
        //   findPrevious: "vuePdfAppFindPrevious",
        //   findResultsCount: "vuePdfAppFindResultsCount",
        //   firstPage: "vuePdfAppFirstPage",
        //   lastPage: "vuePdfAppLastPage",
        //   nextPage: "vuePdfAppNextPage",
        //   numPages: "vuePdfAppNumPages",
          
        // },
      }
    },
    methods: {
        getcatlink() {
          axios.get(this.BASE_URL + "/categories/qrpdf/"+this.$route.params.catId).then(response => {
              console.log(response.data);
              this.pdf_link = response.data.url; 
          });
        }
    },
    watch: {
      "$route.params.catId": function() {
        if (this.$route.params.catId != '' && this.$route.params.catId != null) {
          // this.pdf_link = this.BASE_URL + "/articles/categories/qr/" + this.$route.params.catId; 
          this.getcatlink();
        }
      },
      "dialogSearch": function() {
        this.btnSearchVisible = !this.dialogSearch;
      }
    },
    mounted: function () {

      console.log(this.$route.params);
        if (this.$route.params.catId != '' && this.$route.params.catId != null) {
          // this.pdf_link = this.BASE_URL + "/articles/categories/qr/" + this.$route.params.catId; 
          // console.log(this.pdf_link);
          this.getcatlink();
        }
      
    }
  };